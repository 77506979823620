<template>
  <v-container>
    <v-card>
      <v-card-title>
        <v-icon large>
          {{ icon }}
        </v-icon>
        งาน SI
      </v-card-title>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          color="secondary"
          single-line
          hide-details
        />
      </v-card-actions>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="customerSIs"
          :search.sync="search"
          :options.sync="options"
          :footer-props="{ itemsPerPageOptions: [10, 50, 100] }"
          :loading="loading"
          loading-text="Loading..."
          :server-items-length="totalCustomerSIs"
          class="elevation-1"
        >
          <template v-slot:[`item.requestBudget`]="{ item }">
            <span>
              {{
                item.requestBudget !== 0
                  ? new Intl.NumberFormat("th", {
                      style: "currency",
                      currency: "THB",
                    }).format(item.requestBudget)
                  : "Free"
              }}
            </span>
          </template>
          <template v-slot:[`item.income`]="{ item }">
            <span>
              {{
                item.income !== 0
                  ? new Intl.NumberFormat("th", {
                      style: "currency",
                      currency: "THB",
                    }).format(item.income)
                  : "Free"
              }}
            </span>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-btn
              small
              color="secondary"
              class="ma-2"
              outlined
              :to="'/customer/' + item.customer.id + '/show'"
            >
              <v-icon>
                mdi-magnify
              </v-icon>
              Detail
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { mdiCogs } from '@mdi/js';

moment.locale("th");

export default {
  name: "CustomerSIList",
  props: {},
  data() {
    return {
      icon: mdiCogs,
      search: "",
      totalCustomerSIs: 0,
      options: {},
      customerSIs: [],
      loading: false,
      headers: [
        {
          text: "ชื่อลูกค้า",
          value: "customer.name",
          sortable: false,
          align: "center",
        },
        {
          text: "บริการ",
          value: "customerSICategory.name",
          sortable: false,
          align: "center",
        },
        {
          text: "รายละเอียด",
          value: "description",
          sortable: false,
          align: "center",
        },
        {
          text: "สถานะ",
          value: "customerSIStatus.name",
          sortable: false,
          align: "center",
        },
        {
          text: "สถานะงบประมาณ",
          value: "customerSIBudgetStatus.name",
          sortable: false,
          align: "center",
        },
        {
          text: "งบประมาณที่ขอ",
          value: "requestBudget",
          sortable: false,
          align: "center",
        },
        {
          text: "รายได้",
          value: "income",
          sortable: false,
          align: "center",
        },
        {
          text: "ส่วนงานขาย",
          value: "saleSector",
          sortable: false,
          align: "center",
        },
        {
          text: "พนักงานขาย",
          value: "salePerson",
          sortable: false,
          align: "center",
        },
        {
          text: "",
          value: "action",
          sortable: false,
          align: "center",
        },
      ],
    };
  },
  watch: {
    options: {
      handler() {
        this.getDataFromAPI();
      },
      deep: true,
    },
    search: {
      handler() {
        this.getDataFromAPI();
      },
      deep: true,
    },
  },
  created() {

  },
  methods: {
    formatDate(date) {
      return moment(date).format("LLL");
    },
    getDataFromAPI() {
      this.loading = true;
      const queryParams = {
        params: {
          search: this.search,
          page: this.options.page,
          per_page: this.options.itemsPerPage,
        },
      };
      axios
        .get("/customer-si", queryParams)
        .then((response) => {
          // JSON responses are automatically parsed.
          this.customerSIs = response.data.data;
          this.totalCustomerSIs = response.data.total;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
